@media (min-width: 1200px) {
    .container{
        max-width: 1450px;
    }
}

#back_button{
    margin-bottom: 2%;
}

td {
    text-align: center;
    vertical-align: middle;
  }

.bi:hover{
    opacity: 0.8;
}