
#close_button:hover{
    cursor: pointer;
    opacity: 0.8;
}

#clear_filter{
    float: right;
    margin-right: 2%;
}

#close_button{
    float: right;
}

#clear_filter:hover:enabled{
    cursor: pointer;
    opacity: 0.8;
}

#clear_filter:hover:disabled{
    cursor: pointer;
    background-color: #333;
}

#apply_filter:hover:enabled{
    cursor: pointer;
    opacity: 0.8;
}

#apply_filter:hover:disabled{
    cursor: pointer;
    background-color: #333;
}

#apply_filter:disabled{
    background-color: #333;
}

.tags{
    font-size: 14px;
}

#tag_table{
    height:300px;
    overflow-y: auto;
}

.dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    border:1px solid black;
  }
