.modal {
    display:    none;
    position:   fixed;
    z-index:    1000;
    top:        0;
    left:       0;
    height:     100%;
    width:      100%;
    background: rgba( 255, 255, 255, .8 )
                url('../guide-lines/loading_gif.gif')
                50% 50%
                no-repeat;
}

/* When the body has the loading class, we turn
the scrollbar off with overflow:hidden */
body.loading .modal {
    overflow: hidden;
}

/* Anytime the body has the loading class, our
modal element will be visible */
body.loading .modal {
    display: block;
}

.btn-primary:disabled{
    cursor: pointer;
    background-color: #333;
}

.btn-primary:hover:disabled{
    cursor: pointer;
    background-color: #333;
}