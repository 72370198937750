#openseadragon {
    height: 100vh;
    position: relative; 
    width: 100%;
    float:left;
}

#commentSection{
    position: relative;
    width: 25%;
    display: none;
    float: left;
}

#comment_container{
    margin-left: 3%;
    margin-top: 1%;
    border-radius: 3%;
    overflow:hidden;
    max-height: 100vh;
}

#comment_text{
    width: 100%;
    border-radius: 2%;
}

#comment_read{
    max-height: 60vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
}

#comment_form{
    position: relative;
    bottom: 0;    
}

.trash_icon:hover{
    cursor: pointer;
}

#comment_content{
    position: absolute; 
}

#comment_part{
    height: 80%;
    border: 1px solid #333;
}

#restart_button:hover{
    cursor: pointer;
    opacity: 0.8;
}

#user_button:hover{
    cursor: pointer;
    opacity: 0.8;
}

#toolDiv{
    width:99%;
    float:left;
    height:40px;
    margin-top: 1%;
}

#toolbar{
    background-color: rgb(241, 241, 241);
    width: 99.8%;
    margin-left: 0.2%;
}

#taglist:hover{
    cursor: pointer;
    opacity: 0.8;
}

#tagfilter:hover{
    cursor: pointer;
    opacity: 0.8;
}

#polygon:hover{
    cursor: pointer;
    opacity: 0.8;
}

#tag_comments:hover{
    cursor: pointer;
    opacity: 0.8;
}

#settings:hover{
    cursor: pointer;
    opacity: 0.8;
}

@media screen and (max-width : 480px)
{
    #openseadragon{
        height: 300px;
        min-width: 800px;
        position: relative; 
        display: inline-block;
    }
}

.container{
    margin-top: 2%;
    width: 100%;
    height: 100%;
}

#back_button:hover{
    opacity: 0.8;
}

.btn-group{
    margin-left:2%;
    margin-bottom:1%;
}

.a9s-annotation[name="ADENOCARCINOMA"] .a9s-inner{
    stroke: green;
    border: 1px solid green;
}

.a9s-annotation[name="ADENOCARCINOMA"] .a9s-outer{
    stroke: green;
    border: 1px solid green;
}

.a9s-annotation[name="EPITELIO_NORMALE"] .a9s-inner{
   stroke: yellow;
}

.a9s-annotation[name="EPITELIO_NORMALE"] .a9s-outer{
    stroke: yellow;
 }

.a9s-annotation[name="EPITELIO_IPERPLASTICO"] .a9s-inner{
    stroke: #42FFC7;
}

.a9s-annotation[name="EPITELIO_IPERPLASTICO"] .a9s-outer{
    stroke: #42FFC7;
}

.a9s-annotation[name="DISPLASIA_BASSO_GRADO"] .a9s-inner{
    stroke: red;
}

.a9s-annotation[name="DISPLASIA_BASSO_GRADO"] .a9s-outer{
    stroke: red;
}

.a9s-annotation[name="TONACA_MUSCOLARE"] .a9s-inner{
    stroke: blue;
}

.a9s-annotation[name="TONACA_MUSCOLARE"] .a9s-outer{
    stroke: blue;
}

.a9s-annotation[name="NECROSI"] .a9s-inner{
    stroke: black;
}

.a9s-annotation[name="NECROSI"] .a9s-outer{
    stroke: black;
}

.a9s-annotation[name="AGGREGATI_LINFOCITARI"] .a9s-inner{
    stroke: #F7C548;
}

.a9s-annotation[name="AGGREGATI_LINFOCITARI"] .a9s-outer{
    stroke: #F7C548;
}

.a9s-annotation[name="COMPONENTE_MUCINOSA"] .a9s-inner{
    stroke: #3399ff;
}

.a9s-annotation[name="COMPONENTE_MUCINOSA"] .a9s-outer{
    stroke: #3399ff;
}

.a9s-annotation[name="TESSUTO_ADIPOSO"] .a9s-inner{
    stroke: brown;
}

.a9s-annotation[name="TESSUTO_ADIPOSO"] .a9s-outer{
    stroke: brown;
}

.a9s-annotation[name="GANGLI"] .a9s-inner{
    stroke: orange;
}

.a9s-annotation[name="GANGLI"] .a9s-outer{
    stroke: orange;
}

.a9s-annotation[name="TESSUTO_GRANULAZIONE"] .a9s-inner{
    stroke: #666633;
}

.a9s-annotation[name="TESSUTO_GRANULAZIONE"] .a9s-outer{
    stroke: #666633;
}

.a9s-annotation[name="MUCOSA"] .a9s-inner{
    stroke: #BEDCFE;
}

.a9s-annotation[name="MUCOSA"] .a9s-outer{
    stroke: #BEDCFE;
}

.a9s-annotation[name="REAZIONE_DESMOPLASTICA"] .a9s-inner{
    stroke: #d89216;
}

.a9s-annotation[name="REAZIONE_DESMOPLASTICA"] .a9s-outer{
    stroke: #d89216;
}

.a9s-annotation[name="DISPLASIA_ALTO_GRADO"] .a9s-inner{
    stroke: #00EAFF;
}

.a9s-annotation[name="DISPLASIA_ALTO_GRADO"] .a9s-outer{
    stroke: #00EAFF;
}

.a9s-annotation[name="MARGINE_RESEZIONE_NEGATIVO"] .a9s-inner{
    stroke: #3E6680;
}

.a9s-annotation[name="MARGINE_RESEZIONE_NEGATIVO"] .a9s-outer{
    stroke: #3E6680;
}

.a9s-annotation[name="MARGINE_RESEZIONE_DISPLASIA_BASSO"] .a9s-inner{
    stroke: #721121;
}


.a9s-annotation[name="MARGINE_RESEZIONE_DISPLASIA_BASSO"] .a9s-outer{
    stroke: #721121;
}

.a9s-annotation[name="MARGINE_RESEZIONE_DISPLASIA_ALTO"] .a9s-inner{
    stroke: #FFCF99;
}

.a9s-annotation[name="MARGINE_RESEZIONE_DISPLASIA_ALTO"] .a9s-outer{
    stroke: #FFCF99;
}

.a9s-annotation[name="EMAZIE"] .a9s-inner{
    stroke: #E40066;
}

.a9s-annotation[name="EMAZIE"] .a9s-outer{
    stroke: #E40066;
}

.a9s-annotation[name="PLASMACELLULE"] .a9s-inner{
    stroke: #03CEA4;
}

.a9s-annotation[name="PLASMACELLULE"] .a9s-outer{
    stroke: #03CEA4;
}

