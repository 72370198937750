Link{
  text-decoration: none;
}

html{
  background-color: transparent !important;
}

.btn-primary{
    background-color: #cc0099;
    border: 1px solid #cc0099;
}

.btn-primary:hover{
    opacity: 0.8;
    background-color: #cc0099;
}

.btn-light{
  background-color: purple;
  color: white;
}

.btn-light:hover{
  background-color: purple;
  color: white;
  opacity: 0.8;
}

link {
  text-decoration: none;
}

.custom-file{
  margin-top: 3%;
  margin-bottom: 2%;
}

.jumbotron {
  background-size: cover;
  background-position: top center;
  height: auto;
  width:100%;
}

table td{
  width:10%;
}

#change_pwd{
  justify-content: center;
}

#slide_list table td{
  width: 20%;
}

#spinner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.App{
  position: relative;
}
