.bi:hover{
    cursor: pointer;
    text-decoration: none;
}

.container{
    background-color: transparent !important;
}

#desc:hover{
    opacity: 0.8;
    cursor: pointer;
}
